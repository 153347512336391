<template>
  <div>
    <!-- section 1 -->
    <section class="hero">
      <div class="container stretch">
        <div class="columns is-multiline is-align-items-center z-2">
          <div class="column is-7">
            <h1 class="hero__title">
              Affordable One to One<br />Maths &amp; English Tuition<br />for ages 5-16!
            </h1>
            <div class="hero__content">
              <strong>The very best of ALL Private Tuition options</strong>
              combined into the UK's most comprehensive proven programme to
              support your children during their time at school.
            </div>
            <div class="hero__buttons-section">
              <button class="hero__buttons-section__btn btn_tertiary">
                <div>
                  <i class="fa fa-check"></i>
                  <span
                    >1-to-1 Tuition Sessions with fully qualified teachers</span
                  >
                </div>
              </button>
              <button class="hero__buttons-section__btn btn_secondary">
                <div>
                  <i class="fa fa-check"></i>
                  <span>Freephone Teacher Line when help is needed</span>
                </div>
              </button>
              <button class="hero__buttons-section__btn btn_primary_d">
                <div>
                  <i class="fa fa-check"></i>
                  <span>Online Learning Programme tailored to your child</span>
                </div>
              </button>
              <button class="hero__buttons-section__btn btn_quaternary">
                <div>
                  <i class="fa fa-check"></i>
                  <span>Price per family, not per subject or per child</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <img
          src="@/assets/slide_image_estia.png"
          alt=""
          class="is-hidden-mobile"
        />
      </div>
    </section>
    <!-- Section 2 -->
    <section class="icon_links">
      <div class="container">
        <div class="columns is-mobile is-multiline is-align-items-center">
          <div class="column is-3-desktop is-12-mobile is-6-tablet">
            <div>
              <img src="@/assets/icon_8_estia.png" alt="" /> Covers all children
              in BOTH subjects
            </div>
          </div>
          <div class="column is-3-desktop is-12-mobile is-6-tablet">
            <div>
              <img src="@/assets/icon_2_estia.png" alt="" /> Work with <br />
              live teachers
            </div>
          </div>
          <div class="column is-3-desktop is-12-mobile is-6-tablet">
            <div>
              <img src="@/assets/icon_3_estia.png" alt="" /> Comprehensive,
              individually tailored assessment
            </div>
          </div>
          <div class="column is-3-desktop is-12-mobile is-6-tablet">
            <div>
              <img src="@/assets/icon_4_estia.png" alt="" /> Trusted by <br />
              UK schools
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Section 3 -->
    <section class="testimonials">
              <div class="container">
                <h2 class="testimonials__heading"> Loved by Children. Trusted by Parents. </h2>
                <div role="group" aria-label="Splide Basic HTML Example" class="splide splide--slide splide--ltr splide--draggable is-active is-overflow is-initialized" ref="splide" id="splide01" aria-roledescription="carousel">
                  <div class="splide__track splide__track--slide splide__track--ltr splide__track--draggable" id="splide01-track" style="padding-left: 0px; padding-right: 0px;" aria-live="polite" aria-atomic="true" aria-busy="false">
                    <ul class="splide__list" id="splide01-list" role="presentation" style="transform: translateX(0px);">
                      <li class="splide__slide is-active is-visible" id="splide01-slide01" role="tabpanel" aria-roledescription="slide" aria-label="1 of 6" style="width: calc(33.3333%);">
                        <div class="testimonial__content">
                          <div class="rating"><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>
                          </div>
                          <div class="testimonial__text"> "No more worrying and that's the great part, because I was always scared of tests and now it is different!" </div>
                          <div class="testimonial__user">
                            <img src="@/assets/estia_test1.jpg" alt="">
                            <div class="testimoanial__user__info">
                              <p class="testimoanial__user__name">Koby</p>
                              <p class="testimoanial__user__country">Surrey</p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="splide__slide is-visible is-next" id="splide01-slide02" role="tabpanel" aria-roledescription="slide" aria-label="2 of 6" style="width: calc(33.3333%);">
                        <div class="testimonial__content">
                          <div class="rating"><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>
                          </div>
                          <div class="testimonial__text"> "Tailor made system for my son. And help available just a phone call away!" </div>
                          <div class="testimonial__user">
                            <img src="@/assets/icon_estia_2.jpg" alt="">
                            <div class="testimoanial__user__info">
                              <p class="testimoanial__user__name">Lucyana</p>
                              <p class="testimoanial__user__country">UK</p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="splide__slide is-visible" id="splide01-slide03" role="tabpanel" aria-roledescription="slide" aria-label="3 of 6" style="width: calc(33.3333%);">
                        <div class="testimonial__content">
                          <div class="rating"><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>
                          </div>
                          <div class="testimonial__text"> "I'm now in the top group in my class. It's easy for me to study at home now." </div>
                          <div class="testimonial__user">
                            <img src="@/assets/estia_test_3.jpg" alt="">
                            <div class="testimoanial__user__info">
                              <p class="testimoanial__user__name"></p>
                              <p class="testimoanial__user__country">Julia Cottesloe</p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="splide__slide" id="splide01-slide04" role="tabpanel" aria-roledescription="slide" aria-label="4 of 6" style="width: calc(33.3333%);" aria-hidden="true">
                        <div class="testimonial__content">
                          <div class="rating"><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>
                          </div>
                          <div class="testimonial__text"> "Now I do know the answer. It gives me fantastic confidence to want to do better all the time." </div>
                          <div class="testimonial__user">
                            <img src="@/assets/estia_test_4.jpg" alt="">
                            <div class="testimoanial__user__info">
                              <p class="testimoanial__user__name">Catherine</p>
                              <p class="testimoanial__user__country">Westmead</p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="splide__slide" id="splide01-slide05" role="tabpanel" aria-roledescription="slide" aria-label="5 of 6" style="width: calc(33.3333%);" aria-hidden="true">
                        <div class="testimonial__content">
                          <div class="rating"><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>
                          </div>
                          <div class="testimonial__text"> "The results have been unbelievable! Our daughter has completely changed her study habits." </div>
                          <div class="testimonial__user">
                            <img src="@/assets/estia_test_5.jpg" alt="">
                            <div class="testimoanial__user__info">
                              <p class="testimoanial__user__name">Mark</p>
                              <p class="testimoanial__user__country">Guildford</p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="splide__slide" id="splide01-slide06" role="tabpanel" aria-roledescription="slide" aria-label="6 of 6" style="width: calc(33.3333%);" aria-hidden="true">
                        <div class="testimonial__content">
                          <div class="rating"><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>
                          </div>
                          <div class="testimonial__text"> "We had tried everything. After 6 months, his improvement is so dramatic that we can't believe it's the same boy!" </div>
                          <div class="testimonial__user">
                            <img src="@/assets/estia_test_6.jpg" alt="">
                            <div class="testimoanial__user__info">
                              <p class="testimoanial__user__name">Kerry</p>
                              <p class="testimoanial__user__country">Liverpool</p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
    <!-- section 4 -->
    <section class="programmes">
      <div class="container">
        <p class="programmes__heading">What's included?</p>
        <h2 class="programmes__title">
          Everything Your Child <br />
          Needs to Succeed
        </h2>
        <div class="columns is-mobile is-multiline programmes__cards stretch">
          <div class="column is-4-desktop is-12-mobile is-4-tablet">
            <div class="card">
              <div class="card__image">
                <img src="@/assets/photo2estia.jpg" alt="" />
                <div class="card__icon card__icon--secondary">
                  <i class="fa fa-check"></i>
                </div>
              </div>
              <div class="card__content">
                <p class="card__content__label">INCLUDED</p>
                <p class="card__content__title">1-to 1 Tuition Sessions</p>
                <ul class="card__content__list">
                  <li>
                    <i class="fa fa-check"></i> Individually tailored sessions
                    with fully <br />
                    qualified teachers
                  </li>
                  <li><i class="fa fa-check"></i> Weekly homework</li>
                  <li>
                    <i class="fa fa-check"></i> Weekly goals to keep children
                    engaged
                  </li>
                  <li><i class="fa fa-check"></i> Student Mentoring</li>
                  <li>
                    <i class="fa fa-check"></i> Support for Current Schoolwork
                    and Homework
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="column is-4-desktop is-12-mobile is-4-tablet">
            <div class="card">
              <div class="card__image">
                <img src="@/assets/photo1estia.jpg" alt="" />
                <div class="card__icon card__icon--primary">
                  <i class="fa fa-check"></i>
                </div>
              </div>
              <div class="card__content">
                <p class="card__content__label">INCLUDED</p>
                <p class="card__content__title">Online Learning Programme</p>
                <ul class="card__content__list">
                  <li>
                    <i class="fa fa-check"></i> Online lessons accessible 24/7
                  </li>
                  <li>
                    <i class="fa fa-check"></i> Individually Tailored
                    assessments
                  </li>
                  <li><i class="fa fa-check"></i> Personalized lesson plans</li>
                  <li>
                    <i class="fa fa-check"></i> Aligns with school curriculums
                  </li>
                  <li>
                    <i class="fa fa-check"></i> Access to performance reports
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="column is-4-desktop is-12-mobile is-4-tablet">
            <div class="card">
              <div class="card__image">
                <img src="@/assets/photo3estia.jpg" alt="" />
                <div class="card__icon card__icon--tertiary">
                  <i class="fa fa-check"></i>
                </div>
              </div>
              <div class="card__content">
                <p class="card__content__label">INCLUDED</p>
                <p class="card__content__title">Freephone Teacher Line</p>
                <ul class="card__content__list">
                  <li>
                    <i class="fa fa-check"></i> Access to qualified teachers
                  </li>
                  <li><i class="fa fa-check"></i> Support for Estia Lessons</li>
                  <li><i class="fa fa-check"></i> Support for schoolwork</li>
                  <li>
                    <i class="fa fa-check"></i> Interactive Whiteboard
                    capabilities to <br />
                    work through problems together
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Section 5 -->
    <section class="stats">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column">
            <div class="stats__card">
              <img src="@/assets/icon_stats4.png" alt="" />
              <div class="stats__top__content">
                <p class="stats__top__content___title">Minimal Parent Time</p>
                <p class="stats__top__content___description">
                  We take care of the teaching <br />and support.
                </p>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="stats__card">
              <img src="@/assets/icons_stats2.png" alt="" />

              <div class="stats__top__content">
                <p class="stats__top__content___title">Flexible Schedule</p>
                <p class="stats__top__content___description">
                  Study any time that is best for<br />
                  your family.
                </p>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="stats__card">
              <img src="@/assets/icon_stats3.png" alt="" />
              <div class="stats__top__content">
                <p class="stats__top__content___title">For the Entire Family</p>
                <p class="stats__top__content___description">
                  One subscription covers your<br />
                  entire family.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="stats__block">
          <img src="@/assets/bgstats.jpg" alt="" />
          <div class="stats__block__inner">
            <h2 class="stats__block__title">
              Estia students are confident students.
            </h2>
            <p class="stats__block__description">
              Our results speak for themselves...
            </p>

            <exemplar-lesson-stats>
              <div class="columns is-multiline">
                <div class="column">
                  <div class="stat__value">
                    <exemplar-lesson-stat-field field="today" />
                  </div>
                  <div class="stat__index">Lessons done today</div>
                </div>
                <div class="column">
                  <div class="stat__value">
                    <exemplar-lesson-stat-field field="average" />
                  </div>
                  <div class="stat__index">Average mark</div>
                </div>
                <div class="column">
                  <div class="stat__value">
                    <exemplar-lesson-stat-field field="mtd" />
                  </div>
                  <div class="stat__index">Lessons this month</div>
                </div>
                <div class="column">
                  <div class="stat__value">
                    <exemplar-lesson-stat-field field="ytd" />
                  </div>
                  <div class="stat__index">Lessons this year</div>
                </div>
              </div>
            </exemplar-lesson-stats>
          </div>
        </div>
      </div>
    </section>
    <!-- section 6 -->
    <section class="hero--inner__empty">
      <div class="container stretch">
        <div class="columns is-multiline">
          <div class="column is-7">
            <img src="@/assets/image-inner_emtpy.png" alt="" />
          </div>
          <div class="column is-5">
            <div class="hero__content">
              <ul class="hero__list__tabs">
                <li>ε Increased engagement among learners</li>
                <li>ε Improved problem-solving skills</li>
                <li>ε Better retention of information</li>
                <li>ε Study at home in own time and environment</li>
                <li>ε The best private tuition option available</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- section 7-->
    <section class="timeline">
      <!-- CONTENT BLOCK -->
      <div class="container is-fluid bg-light-blue">
        <div class="container">
          <div
            class="columns is-align-items-center is-justify-content-center p-tb-4 z-2"
          >
            <div class="column">
              <div class="timeline__content timeline__content__reverse">
                <h2 class="timeline__content__title">Affordable Tuition</h2>
                <div class="timeline__content__description">
                  Estia offers high-end 1-to-1 tuition at affordable prices,
                  meaning that your children can get the support they need to
                  succeed academically while learning at home.
                </div>
              </div>
            </div>
            <div class="column">
              <img src="@/assets/timeline1.png" alt="" />
            </div>
          </div>
        </div>
        <div class="path-to-left"></div>
      </div>
      <!-- /CONTENT BLOCK -->
      <!-- CONTENT BLOCK -->
      <div class="container is-fluid bg-light-blue">
        <div class="container">
          <div
            class="columns is-align-items-center is-justify-content-center p-tb-4"
          >
            <div class="column">
              <img src="@/assets/timeline2.png" alt="" />
            </div>
            <div class="column">
              <div class="timeline__content">
                <h2 class="timeline__content__title">
                  Additional access to qualified teachers
                </h2>
                <div class="timeline__content__description">
                  Of course, your child will have questions between their 1-to-1
                  sessions. Instead of asking you, we provide live support from
                  real teachers. Your child can simply call when they need help!
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="path-to-right"></div>
      </div>
      <!-- /CONTENT BLOCK -->
      <!-- CONTENT BLOCK -->
      <div class="container is-fluid bg-light-blue">
        <div class="container">
          <div
            class="columns is-align-items-center is-justify-content-center p-tb-4"
          >
            <div class="column">
              <div class="timeline__content timeline__content__reverse">
                <h2 class="timeline__content__title">
                  Flexible to fit around you
                </h2>
                <div class="timeline__content__description">
                  With no need to find a tutor near you, we have made sure that
                  our solution is as flexible as possible. You set the routine
                  and schedule that is best for your family and we'll provide
                  the teaching, marking and live support that will help your
                  child grow.
                </div>
              </div>
            </div>
            <div class="column">
              <img src="@/assets/timeline3.png" alt="" />
            </div>
          </div>
        </div>
        <div class="path-to-left"></div>
      </div>
      <!-- /CONTENT BLOCK -->
      <!-- CONTENT BLOCK -->
      <div class="container is-fluid bg-light-blue-last">
        <div class="container">
          <div
            class="columns is-align-items-center is-justify-content-center p-tb-4"
          >
            <div class="column">
              <img src="@/assets/timeline4.png" alt="" />
            </div>
            <div class="column">
              <div class="timeline__content">
                <h2 class="timeline__content__title">Minimal parent time</h2>
                <div class="timeline__content__description">
                  Your time is precious these days. Our programme is built to be
                  effective with very little parental involvement: we take care
                  of the lesson planning, teaching, marking and live support.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /CONTENT BLOCK -->
    </section>
    <!-- section 8-->
    <section class="details">
      <div class="container">
        <div>
          <h2 class="details__title_h1">
            In addition to your 1-1 tuition sessions….
          </h2>
        </div>
        <div class="columns is-mobile is-multiline">
          <div class="column is-4-desktop is-12-mobile is-6-tablet">
            <h2 class="details__title">Personalised to Exact Level</h2>
            <div class="details__description">
              Take the free assessment and we'll create the <br />first lesson
              plan tailored precisely to fit your <br />child's needs.
            </div>
          </div>
          <div class="column is-4-desktop is-12-mobile is-6-tablet">
            <h2 class="details__title">Aligned to Curriculum</h2>
            <div class="details__description">
              Lessons use the same teaching methods <br />
              used in UK schools and are fully aligned with <br />your child's
              school curriculum.
            </div>
          </div>
          <div class="column is-4-desktop is-12-mobile is-6-tablet">
            <h2 class="details__title">Comprehensive Curriculum</h2>
            <div class="details__description">
              Over 2,000 on-demand lessons and 6 <br />million questions
              covering maths and <br />English from Year 1 through Year 11.
            </div>
          </div>
          <div class="column is-4-desktop is-12-mobile is-6-tablet">
            <h2 class="details__title">Access to Teachers</h2>
            <div class="details__description">
              Our team of qualified teachers are available to <br />support you
              and your child via our Freephone <br />Teacher Line.
            </div>
          </div>
          <div class="column is-4-desktop is-12-mobile is-6-tablet">
            <h2 class="details__title">Saving You Money</h2>
            <div class="details__description">
              Lessons use the same teaching methods <br />used in UK schools and
              are fully aligned with <br />your child's school curriculum.
            </div>
          </div>
          <div class="column is-4-desktop is-12-mobile is-6-tablet">
            <h2 class="details__title">Builds Confidence</h2>
            <div class="details__description">
              Over 2,000 on-demand lessons and 6 <br />million questions
              covering maths and <br />English from Year 1 through Year 11
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- section 9 -->
    <section class="hero--inner">
      <div class="container stretch">
        <div
          class="mt-hero--inner columns is-multiline is-align-items-center z-2 mt-10_touch-0"
        >
          <div class="column">
            <h2 class="hero__title hero__title--underline">
              Speaking with parents we've found that...
            </h2>
            <div class="hero__content">
              <h3 class="hero--inner__intro">Over 90%...</h3>
              <ul class="no-decoration">
                <li>of children are at least one year behind</li>
                <li>of those parents had no idea</li>
                <li>of parents don't feel they can help</li>
              </ul>
            </div>
          </div>
          <div class="column">
            <img src="@/assets/image_hero2.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scope src="@/assets/css/home_bulma.min.css"></style>

<script lang="ts">
import { defineComponent } from "vue";
import Splide from '@splidejs/splide'
import '@splidejs/splide/css'

export default defineComponent({
  layout: "default",
  setup () {
    let splide: Splide;
    const instance = getCurrentInstance()
    onUnmounted(() => {
      splide?.destroy(true)
    })
    onMounted(() => {
      splide = new Splide( instance.proxy.$refs.splide as HTMLElement, {
            focus : 0,
            perPage: 3,
            perMove: 1,
            arrows: false,
            dots: true,
            pagination: true,
            breakpoints: {
                840: {
                  perPage: 3,
                  perMove: 1,
                },
                512: {
                  perPage: 1,
                  perMove: 1,
                },
              }
          } );
          splide.mount();
    })
  }
});
</script>

